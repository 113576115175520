* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.TheoryPortal {
  background-color: #111;
  font-family: "Antonio", sans-serif;
  color: white;
  padding-bottom: 4rem;
}
.imageSection {
  position: relative;
  width: 100%;
  padding: 8rem 0;
  background-image: url("../../assets//images/theorySupportbanner.png");
  background-position: center;
  background-size: cover;
}
.opicity {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: #111;
}
.maincontent {
  display: flex;
  max-width: 1440px;
  width: 100%;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  position: sticky;
}

.content {
  width: 100%;
  color: white;
  text-align: center;
  padding: 0px 20px;
}
.content hr {
  opacity: 1;

  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
  border: 2px solid #db0000;
}

.heading1 h1 span {
  color: rgb(255, 230, 0);
}
.heading1 h1 {
  color: #d10101;
  font-size: 6rem;
  font-weight: 900;

  text-align: center;
  overflow: hidden;
 
  white-space: nowrap;
  margin: 0 auto;

  animation: typing 3.5s steps(30, end), blink-caret 0.5s step-end;
  padding: 2rem 0px;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: rgb(255, 0, 0);
  }
}

.heading2 p {
  color: #ffe600;
  font-size: 30px;

  padding-top: 0.8rem;
  margin-bottom: 20px;
}

.video {
  width: 100%;
  text-align: center;
}

.video img {
  width: 100%;
  max-width: 400px;
}
@media (max-width: 1265px) {
  .maincontent {
    flex-direction: column;
  }
  .video {
    max-width: 800px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
  }
}
@media (max-width: 1020px) {
  .content {
    padding-top: 75px;
  }
}
@media (max-width: 767px) {
  .content {
    padding-top: 0px;
  }
  .heading2 p {
    font-size: 24px;
  }

  .maincontent {
    display: block;
  }
  .heading1 {
    padding-top: 1rem;
  }
  .heading1 h1 {
    font-size: 5rem;
  }
  .content {
    max-width: 100%;
  }
  .video {
    max-width: 100%;
  }
}
@media (max-width: 350px) {
  .video {
    padding: 40px;
  }
  .heading1 h1 {
    font-size: 2rem;
    line-height: 28px;
  }
  .heading2 p {
    font-size: 16px;
  }
}
/* //////////////////////////////////////////// */
.theoryTestSectionQ {
  padding: 2rem 1rem;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}
.theoryTestDivQ h2 span {
  color: rgb(255, 217, 0);
}
.theoryTestDivQ h2 {
  font-size: 5rem;
  font-weight: 700;
  overflow: hidden;
 
  white-space: nowrap;
  margin: 0 auto;

  color: #ff090d;
  animation: typing 3.5s steps(30, end), blink-caret 0.5s step-end;
  padding: 2rem 0px;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: rgb(255, 0, 0);
  }
}
.theoryTestDivQ hr {
  border: 2px solid #ff090d;
  opacity: 1;
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
}
.theoryTestDivQ p {
  color: rgb(255, 230, 0);
  font-size: 24px;
}
@media (max-width: 778px) {
  .theoryTestDivQ h2 {
    font-size: 4rem;
  }
}
@media (max-width: 578px) {
  .theoryTestDivQ h2 {
    font-size: 3.3rem;
  }
}
@media (max-width: 480px) {
  .theoryTestDivQ h2 {
    font-size: 2.6rem;
  }
}
@media (max-width: 420px) {
  .theoryTestDivQ h2 {
    font-size: 2.2rem;
  }
}
/* ////////////////////////////////////////////////////// */

.choicesSectionTheoryPortal {
  padding: 2rem 1rem;
  max-width: 1270px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
}
.choicesDivTheoryPortal {
  display: flex;
  gap: 20px;
}
#choiceIdMc {
  animation: 5s slide-right;
  background-color: #ff090d;
  color: #ffd000;
}

#choiceIdMc hr {
  border: 2px solid #ffee00;
}
@keyframes slide-right {
  from {
    margin-left: -100%;
  }
  to {
    margin-left: 0%;
  }
}
#choiceIdHP {
  animation: 5s slide-left;
  background-color: #ffd900;
  color: #ff0000;
}

@keyframes slide-left {
  from {
    margin-right: -100%;
  }
  to {
    margin-right: 0%;
  }
}
#choiceIdHP hr {
  border: 2px solid #ff0000;
  opacity: 1;
  max-width: 350px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 1rem;
}
.ChoicesContentContainer {
  margin-top: 1rem;
  padding: 1rem;
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  border-radius: 30px;
  text-align: center;
}
.ChoicesContentContainer h2 {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
.ChoicesContentContainer hr {
  border: 2px solid #ffffff;
  opacity: 1;
  max-width: 350px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 1rem;
}
.ChoicesContentContainer p {
  font-size: 18px;
  margin-bottom: 10px;
  margin: 0px 1rem 10px;
}
@media (max-width: 750px) {
  .choicesDivTheoryPortal {
    display: block;
  }
}
/* ////////////////////////////////////////////// */
.theoryPortalYoutubeVideosSection {
  padding: 2rem 1rem;
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
}
.theoryPortalYoutubeVideosDiv iframe {
  height: 500px;
  width: 100%;
}
/* /////////////////////////////////////////////////////// */

.starImgContainer {
  display: flex;
  justify-content: center;
}

.starImgContainer img {
  max-width: 100px;
  width: 100%;
  animation: slideFromLeft 1s ease-out, rotate 2s linear;
}

.starImgContainer img:nth-child(5) {
  animation-delay: 8s;
}
.starImgContainer img:nth-child(4) {
  animation-delay: 6s;
}
.starImgContainer img:nth-child(3) {
  animation-delay: 4s;
}
.starImgContainer img:nth-child(2) {
  animation-delay: 2s;
}
.starImgContainer img:nth-child(1) {
  animation-delay: 0s;
}

@keyframes slideFromLeft {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* /////////////////////////why choose us css/////////// */
.thMultipleChioceHeader h2 {
  text-align: center;
  color: #ff0004;
  font-size: 4rem;
  font-weight: 700;
  overflow: hidden;
 
  white-space: nowrap;
  margin: 0 auto;

  color: #ff090d;
  animation: typing 3.5s steps(30, end), blink-caret 0.5s step-end;
  padding: 2rem 0px;
}
.thMultipleChioceHeader h2 SPAN {
  color: rgb(255, 238, 0);
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: rgb(255, 0, 0);
  }
}
@media (max-width: 767px) {
  .thMultipleChioceHeader h2 {
    font-size: 3rem;
  }
}
@media (max-width: 557px) {
  .thMultipleChioceHeader h2 {
    font-size: 2.6rem;
  }
}
@media (max-width: 480px) {
  .thMultipleChioceHeader h2 {
    font-size: 2rem;
  }
}
@media (max-width: 380px) {
  .thMultipleChioceHeader h2 {
    font-size: 1.8rem;
  }
}
.thMultipleChioceHeader p {
  font-size: 30px;
  text-align: center;
  color: rgb(255, 238, 0);
}
.thchoiceListSection {
  background-color: #470c0c5b;
}
.features {
  padding: 2rem 0;
}
.features h4 {
  color: #ff0004;
  font-size: 65px;
  font-weight: 700;
  line-height: 1.2em;
  text-align: center;

  margin-bottom: 20px;
}
.features p {
  color: #7a7a7a;
  line-height: 28px;
  text-align: center;
  font-weight: 400;
}
.mainFeatures {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;

  text-align: center;
  max-width: 1500px;
  padding: 0px 1rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}
#column {
  background-color: #ffd904fa;
}
#column:hover {
  box-shadow: 0px 0px 55px 5px rgba(196, 196, 196, 0.203);
  border: 1px solid #ffd000;
}
.column {
  padding: 25px 25px 55px 25px;
  background-color: #cc0000;
  border-radius: 30px;
  box-shadow: 0px 0px 55px 5px rgba(172, 172, 172, 0.171);

  transition: box-shadow 0.3s ease;
}
.column a {
  text-decoration: none;
  color: #ff0004;
}

.column:hover {
  box-shadow: 0px 0px 55px 5px rgba(196, 196, 196, 0.203);
  border: 1px solid #ff0004;
}
.column h3 {
  font-size: 26px;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 10px;
  color: #ffe600;
}
#column h3 {
  font-size: 26px;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 10px;
  color: #ff0004;
}
.column p {
  font-size: 16px;

  font-weight: 300;
  line-height: 1.7em;
  color: #ffffff;
}
.column span {
  background-color: #ffd900;
  padding: 30px;
  border-radius: 50%;
  display: inline-block;
  font-size: 50px;
  color: #fff;
  margin-right: auto;
  margin-left: auto;
}
#column span {
  background-color: #ff0004;
  padding: 30px;
  border-radius: 50%;
  display: inline-block;
  font-size: 50px;
  color: #fff;
  margin-right: auto;
  margin-left: auto;
}

#featuresIcon {
  width: 1em;
  height: 1em;
  position: relative;
  display: block;
}
#column span:hover {
  transform: scale(1.2);
  background-color: #fff;
  transition: 0.3s ease-in-out;
  color: #ff0004;
}
.column span:hover {
  transform: scale(1.2);
  background-color: #fff;
  transition: 0.3s ease-in-out;
  color: #ff0004;
}
#featuresIconSRU {
  font-size: 6rem;
}
@media (max-width: 1085px) {
  .features h4 {
    font-size: 45px;
  }
}
@media (max-width: 767px) {
  .features h4 {
    font-size: 30px;
  }
  .mainFeatures {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

/* ////////////////////////////////// */
.thAdditionalSupportSection {
  padding: 2rem 1rem;
}
.thAdditionalSupportHeader {
  background-color: #c4171a3a;
  padding: 2rem 0px;
}
.thAdditionalSupportHeader h2 span {
  color: rgb(255, 238, 0);
}
.thAdditionalSupportHeader h2 {
  font-size: 4rem;
  font-weight: 700;
  text-align: center;
  color: #fff;
  overflow: hidden;

  white-space: nowrap;
  margin: 0 auto;

  color: #ff090d;
  animation: typing 3.5s steps(30, end), blink-caret 0.5s step-end infinite;

  padding: 2rem 0px;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: rgb(255, 0, 0);
  }
}
.thAdditionalSupportformAndMap {
  max-width: 1240px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.thAdditionalSupport2ndHeader {
  padding: 2rem 0px;
}
.thAdditionalSupport2ndHeader h2 span {
  color: #ffd000;
}
.thAdditionalSupport2ndHeader h2 {
  color: #ff0004;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
}
.thAdditionalSupport2ndHeader hr {
  border: 2px solid #ff0004;
  opacity: 1;
  max-width: 500px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.thAdditionalSupport2ndHeader section {
  box-shadow: 0px 0px 55px 5px rgba(196, 196, 196, 0.203);
  background-color: #ffe600;
  color: rgb(255, 0, 0);
  padding: 3rem;
  border-radius: 30px;
  opacity: 0;
  transform: translateY(-100%);
  animation: slideDown 3s forwards;
}

@keyframes slideDown {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.thAdditionalSupport2ndHeader p {
  text-align: center;
  font-size: 18px;
  margin-bottom: 1rem;
}
.callBackFormAndMap {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.formmap {
  width: 100%;
  display: flex;
  justify-content: center;
}
@media (max-width: 628px) {
  .callBackFormAndMap {
    display: block;
  }
  .thAdditionalSupportHeader h2 {
    font-size: 2rem;
  }
}
.thFooterSection {
  margin: 2rem 0px;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  text-align: center;
}
.thFooterDiv h2 span {
  color: #ffd900;
}
.thFooterDiv h2 {
  color: #ff0004;
  font-size: 2.5rem;
  font-weight: 600;
  text-align: center;
}
.thFooterDiv hr {
  border: 2px solid #ff0004;
  margin-right: auto;
  margin-left: auto;
  max-width: 500px;
  width: 100%;
  opacity: 1;
}
.thFooterDiv img {
  max-width: 700px;
  width: 100%;
}
@media (max-width: 428px) {
  .thFooterDiv h2 {
    font-size: 2rem;
  }
}
@media (max-width: 750px) {
  .starImgContainer img {
    max-width: 95px;
    width: 100%;
  }
}

@media (max-width: 482px) {
  .starImgContainer img {
    max-width: 65px;
    width: 100%;
  }
}
@media (max-width: 452px) {
  .starImgContainer img {
    max-width: 65px;
    width: 100%;
  }
}
@media (max-width: 400px) {
  .starImgContainer img {
    max-width: 45px;
    width: 100%;
  }
}
