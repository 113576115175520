/* Add a gray background color with some padding */
.Cardcontainer {
  background: #000000;
  font-family: "Antonio", sans-serif;
}
.CardHomeBanner {
  position: relative;
  background: url("../../assets/images/contact-banner.png");
  background-size: cover;
  background-position: center;

  padding: 200px 0;
}

.opicity {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: #000000d0;
}
/* Header/Blog Title */
.Cardheader {
  padding: 30px;

  text-align: center;
  position: sticky;
  color: white;
}
.Cardheader h2 {
  font-size: 5rem;
  font-weight: 900;
}
.Card2ndContainer {
  max-width: 1640px;
  margin: 0px auto;
  padding-bottom: 4rem;
}

.PrivledgeCardsHeader h2 {
  margin: 2rem 1rem;
  text-align: center;
  color: white;
  font-size: 4rem;
  font-weight: 700;
}
.PrivledgeCards {
  padding: 4rem 3rem;
  border-radius: 20px;
  margin: 0px 1rem;
  box-shadow: 0 0 20px rgba(158, 158, 158, 0.656);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  gap: 50px;
  background: linear-gradient(135deg, #020071e8, #960039ee);
  place-items: center;
}
.PrivledgeCardsDiv {
  box-shadow: 0px 0px 55px 5px rgba(255, 255, 255, 0.203);
}
.PrivledgeCards img {
  max-width: 800px;
  width: 100%;
  border: 2px solid white;
  border-radius: 10px;
}

@media (max-width: 700px) {
  .CardHomeBanner {
    padding: 160px 0px;
  }
  .Cardheader h2 {
    font-size: 4rem;
  }
}
@media (max-width: 500px) {
  .CardHomeBanner {
    padding: 120px 0px;
  }
  .Cardheader h2 {
    font-size: 3.5rem;
  }
  .PrivledgeCardsHeader h2 {
    font-size: 3rem;
  }
}
