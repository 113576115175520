/* Quiz.module.css */
.quizContainer{
  min-height: 100vh;
  height: auto;
  padding: 5rem 0px;
  background-color: #000000; 
}
.quizDiv {
  display: flex;
  justify-content: center;
  align-items: center;
 height: 100%;
  color: #ffffff; 
}

.quiz {
  background: linear-gradient(  180deg, #0003bf, #04003e);/* dark gray background */
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  padding: 40px 20px;
  width: 100%;
  max-width: 1000px;
}
.totalTimer2{
  display: flex;
  align-items: center;
  gap: 20px;
  color: #ff4040; /* red text */
  font-size: 18px;
  margin-bottom: 10px;
}
.totalTimer,
.questionCount,
.OptionsText {
  color: #ff4040; /* red text */
  font-size: 18px;
  margin-bottom: 10px;
}
.totalTimer333{
  color: #ff4040; /* red text */
  font-size: 32px;
  font-weight: 600;
  justify-content: center;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.totalTimer,
.questionCount{
  display: flex;
  align-items: center;
  gap: 20px;
}
.totalTimer p{
  color: white;
  margin-bottom: 0px;
}
.questionCount div{
  color: white;
}

.questionCount {
  font-size: 20px;
}

.answerSection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 10px;
  padding: 2rem 0px;
}

.answerSection button {
  background-color: #03062e; /* red button background */
  color: #ffffff; /* white text */
  gap: 20px;
  border: 1px solid rgb(255, 2, 82);
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  
  
}
.answerSection button img{
  max-width: 120px;
  width: 100%;
  border-radius: 6px;
  box-Shadow: 0px 4px 10px rgba(31, 31, 31, 0.74); 
}
.answerSection button p{
  margin-bottom: 0px;
  
}

.answerSection button:hover {
  background-color: #ff004c; /* darker red on hover */
}

.navigationButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.navigationButtons button {
  background-color: #000000; /* black button background */
  color: #ffffff; /* white text */
  border: 1px solid #ff4040; /* red border */
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.navigationButtons button:hover {
  background-color: #ff4040; /* dark gray on hover */
  color: #ffffff; /* red text on hover */
}

@media (max-width: 768px) {
  .quiz {
    width: 90%;
    padding: 15px;
  }
  .totalTimer2{
    flex-direction: column;
   align-items: flex-start;
  }
}
@media (max-width: 500px) {
  .questionCount{
    flex-direction: column;
    align-items: flex-start;
  }
}
#questionNumbering{
 margin: 0.2rem;
  border-radius: 50%;
  width: 38px;
  padding: 0.3rem 0px;
  border: none;
  
}