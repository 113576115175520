/* CheckoutForm.css */
.chackoutPageD {
  background-color: black;

  font-family: "Antonio", sans-serif;
}
.checkoutPage {
  display: flex;
  gap: 30px;
  justify-content: center;
  max-width: 1400px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 5rem 2rem;
}
#chackoutpageComponent {
  background: linear-gradient(135deg, #499eff, #0f0546);
  border-radius: 10px;
}
.chackoutPageD .max-w-lg {
  max-width: 35rem;
  width: 100%;
}
.chackoutPageD hr {
  opacity: 1;
  border: 1px solid red;
}

.chackoutPageD .mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.chackoutPageD .p-4 {
  padding: 1rem;
}

.chackoutPageD .bg-white {
  background-color: black;
}

.chackoutPageD .dark\:bg-zinc-800 {
  background-color: black; /* Adjust color as needed */
}

.chackoutPageD .text-black {
  color: rgb(0, 0, 0);
}

.chackoutPageD .dark\:text-white {
  color: rgb(0, 0, 0);
}

.chackoutPageD .text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.chackoutPageD .font-bold {
  font-weight: 700;
}

.chackoutPageD .text-center {
  text-align: center;
}

.chackoutPageD .mb-4 {
  margin-bottom: 1rem;
}

.chackoutPageD .border-red-500 {
  border-color: #f56565;
}

.chackoutPageD .text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.chackoutPageD .font-semibold {
  font-weight: 600;
}

.chackoutPageD .mb-2 {
  margin-bottom: 0.5rem;
}

.chackoutPageD .block {
  display: block;
}

.chackoutPageD .w-full {
  width: 100%;
}

.chackoutPageD .p-2 {
  padding: 0.5rem;
}

.chackoutPageD .border {
  border-width: 1px;
}

.chackoutPageD .border-zinc-300 {
  border-color: #d1d5db; /* Adjust color as needed */
}

.chackoutPageD .rounded {
  border-radius: 0.25rem;
}

.chackoutPageD .text-red-500 {
  color: #f56565;
}

.chackoutPageD .font-semibold {
  font-weight: 600;
}

.chackoutPageD .mb-2 {
  margin-bottom: 0.5rem;
}

.chackoutPageD .mb-4 {
  margin-bottom: 1rem;
}

.chackoutPageD .d-flex {
  display: flex;
}

.chackoutPageD .align-items-center {
  align-items: center;
}

.chackoutPageD .form-check-input {
  margin-left: 10px;
}

.chackoutPageD .form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.chackoutPageD .form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.chackoutPageD .btn-primary {
  color: #fff;
  background-color: #de1515;
  border-color: #de1515;
}

.chackoutPageD .btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.chackoutPageD .account-btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.8rem 3rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.chackoutPageD .account-btn:hover {
  border-color: #fff;
  background-color: #000000;
}

/* ////////////////////////////////////// */

.modal-content {
  max-width: 35rem;
  width: 100%;

  padding: 2rem;
  background: linear-gradient(135deg, #3f0064, #a33edd);
  color: #fff;
  font-family: "Antonio", sans-serif;
}
#modelContent {
  border-radius: 10px;
}
.form-spaced {
  margin-bottom: 1rem;
}

.form-label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
}

.form-input,
.form-textarea {
  margin-top: 0.25rem;
  display: block;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #d4d4d4;
  border-radius: 0.375rem;
  background-color: #ffffff;
}

.section-header {
  font-size: 1.125rem;
  font-weight: 600;
}

.divider {
  margin: 1rem 0;
  border-color: #ef4444;
}

.order-summary {
  margin-top: 0.5rem;
}
@media (max-width: 728px) {
  .checkoutPage {
    display: block;
  }
}
.paymentComponent {
  padding: 8rem 0px;
}

.payment-container {
  max-width: 800px;
  margin: 0px auto;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
.payment-container img {
  max-width: 70px;
  width: 100%;
  margin: 1rem 0px;
}
.payment-container h3 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.payment-container h1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.payment-container h1 span {
  color: #0070f3;
}
.payment-container h3 span {
  color: #0070f3;
}
.payment-form h2 {
  text-align: center;
  font-size: 22px;
  color: #2c3e50;
  margin-bottom: 10px;
}

.payment-form h3 {
  text-align: center;
  font-size: 18px;
  color: #7f8c8d;
  margin: 5px 0;
}

/* Input and Button Styling */
.payment-form input {
  width: 100%;
  padding: 12px;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

.payment-form input:focus {
  border-color: #3498db;
  outline: none;
}

button.pay-button {
  width: 48%;
  padding: 12px;
  margin: 10px 1%;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  background-color: #3498db;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button.pay-button:disabled {
  background-color: #bdc3c7;
  cursor: not-allowed;
}

button.pay-button:hover {
  background-color: #2980b9;
}

/* Displaying Hash Generation Info */
.hash-status {
  text-align: center;
  font-size: 16px;
  color: #16a085;
  margin-top: 20px;
}

/* Adjusting the form layout on smaller screens */
@media (max-width: 600px) {
  .payment-form {
    padding: 15px;
  }

  button.pay-button {
    width: 100%;
    margin: 5px 0;
  }
}

/* Stripe card input field */
.stripe-card-input {
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  margin-bottom: 1rem;
  transition: border-color 0.3s ease-in-out;
}

.stripe-card-input:focus {
  outline: none;
  border-color: #0070f3;
}

/* Stripe button */
.payment-button {
  background-color: #0070f3;
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 4px;
  font-size: 1.2rem;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s;
}

.payment-button:disabled {
  background-color: #d1d1d1;
  cursor: not-allowed;
}

.payment-button:hover:not(:disabled) {
  background-color: #005bb5;
}

/* Loading state */
.loading-text {
  font-size: 1.2rem;
  color: #ff0000;
  text-align: center;
  font-weight: bold;
  margin-top: 1rem;
}

.checkBoxContainer {
  padding: 2rem 0px;
  color: white;
}